import CustomInput from "components/CustomInput";
import Modal from "components/Modal";
import { toast } from "react-toastify";
import { post } from "utilities/requests";

export default function TagModal({
  visible,
  setOpen,
  action,
  new_tag,
  setNewTag,
  refetch,
}) {
  const handleAddTag = async () => {
    const formData = new FormData();
    formData.append("text", new_tag.text);
    const res = await post("edit-page/home/tag", formData);

    if (res.httpCode === 200) {
      refetch();
      setOpen(false);
      toast.success("Tag adicionada com sucesso");
    } else {
      toast.error(res.message || "Erro ao adicionar tag");
    }
  };

  const handleEditTag = async () => {
    const formData = new FormData();
    formData.append("id", new_tag.id);
    formData.append("text", new_tag.text);
    const res = await post(`edit-page/home/tag/update`, formData);

    if (res.httpCode === 200) {
      refetch();
      setOpen(false);
      toast.success("Tag editada com sucesso");
    } else {
      toast.error(res.message || "Erro ao editar tag");
    }
  };

  return (
    <Modal
      title={action === "add" ? "Adicionar tag" : "Editar tag"}
      open={visible}
      setOpen={setOpen}
      maxWidth="30rem"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <CustomInput
          placeholder="Nome da tag"
          value={new_tag.text}
          onChange={(e) => setNewTag({ ...new_tag, text: e.target.value })}
        />

        <label
          htmlFor="upload_tag_button"
          style={{
            textTransform: "uppercase",
            padding: "0.4rem 1rem",
            backgroundColor: "#333333",
            color: "#FFFFFF",
            cursor: "pointer",
            textAlign: "center",
            width: "fit-content",
          }}
          onClick={() => {
            if (action === "add") {
              handleAddTag();
            } else {
              handleEditTag();
            }
          }}
        >
          {action === "add" ? "Adicionar tag" : "Editar tag"}
        </label>
      </div>
    </Modal>
  );
}
