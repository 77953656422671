import { useEffect, useState } from "react";
import SidebarHeader from "./SidebarHeader";
import AdminMenu from "./AdminMenu";

export default function AdminSideNav() {
  const [shrunk, setShrunk] = useState(false);

  const handleShrunk = () => {
    setShrunk(!shrunk);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShrunk(true);
      } else {
        setShrunk(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: shrunk ? "7.2rem" : "26rem",
        backgroundColor: "#FFF",
        borderRight: "1px solid #E0E0E0",
        paddingTop: "0.4rem",
        transition: "0.3s ease-in-out",
        zIndex: 2,
      }}
    >
      <SidebarHeader
        {...{
          shrunk,
          handleShrunk,
        }}
      />

      <hr
        style={{
          width: "100%",
          border: "0.6px solid #e0e0e0",
          margin: 0,
          marginTop: "0.6rem",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0.6rem 0",
          width: "100%",
          gap: "0.4rem",
          overflowY: "auto",
          height: "100%",
        }}
      >
        <AdminMenu {...{ shrunk }} />
      </div>
    </div>
  );
}
