// import { Icon } from "@mui/material";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CaretDown } from "@phosphor-icons/react";

/**
 * @param {Object} props
 * @param {React.Component} props.icon
 * @param {String} props.title
 * @param {String} props.href
 * @param {React.Component} props.children
 * @returns
 * @example
 *
 * - single item:
 * <AsideItem
 *   icon={HomeIcon}
 *   title="Dashboard"
 *   href="/pbo/dashboard"
 *   {...{ shrunk }}
 * />
 *
 * - item with collapse:
 * <AsideItem
 *   icon={WebIcon}
 *   title="Páginas"
 *   {...{ shrunk }}
 * >
 *  <AsideItem
 *    icon={SecurityIcon}
 *    title="Política de Privacidade"
 *    href="/pbo/pages/privacy"
 *    {...{ shrunk }}
 *  />
 *  <AsideItem
 *    icon={GavelIcon}
 *    title="Termos de Uso"
 *    href="/pbo/pages/terms"
 *    {...{ shrunk }}
 *  />
 * </AsideItem>
 **/
const SidebarItem = ({ icon, title, children = null, href, shrunk }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const ref = useRef(null);

  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [this_route, setThisRoute] = useState(false);

  useEffect(() => {
    // get collapse height
    setHeight(ref?.current?.scrollHeight);
  }, []);

  useEffect(() => {
    // check if this route is active to set background color
    if (
      location.pathname.replace("/dashboard", "").includes(href) &&
      href !== "/"
    ) {
      setThisRoute(true);
    } else {
      if (location.pathname.replace("/dashboard", "") === href) {
        setThisRoute(true);
      } else {
        setThisRoute(false);
      }
    }
  }, [location]);

  const handleOpenCollapse = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "0 0.8rem",
      }}
    >
      <div
        className={style.asideItem}
        style={{
          backgroundColor: open ? "#F0F0F0" : "#fff",
          // borderRadius: open ? "0.5rem 0.5rem 0 0" : "0.5rem",
          ...(this_route && {
            backgroundColor: "#333333",
            color: "white",
          }),
        }}
        onClick={
          children
            ? handleOpenCollapse
            : () => {
                navigate("/dashboard" + href);
              }
        }
      >
        <span
          style={{
            fontSize: "1.2rem",
            marginLeft: "0.2rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
        </span>

        <span
          style={{
            fontSize: shrunk ? "0" : "1rem",
            fontWeight: this_route ? "bold" : "normal",
            marginLeft: "0.6rem",
            transition: "font-size 0.3s ease-in-out",
          }}
        >
          {title}
        </span>

        {children && (
          <CaretDown
            style={{
              fontSize: "1.2rem",
              marginLeft: "auto",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "0.3s ease-in-out",
            }}
          />
        )}
      </div>

      {children && (
        <div
          ref={ref}
          style={{
            paddingTop: "0.4rem",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: open ? height + 16 : 0,
            overflow: "hidden",
            transition: "0.3s ease-in-out",
            opacity: open ? 1 : 0,
            backgroundColor: open ? "#F0F0F0" : "transparent",
            paddingBottom: open ? "0.8rem" : 0,
            gap: "0.4rem",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default SidebarItem;
