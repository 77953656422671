import { Star } from "@phosphor-icons/react";

export default function StarsCountSection({
  count_info = [
    { percentage: 100, count: 1 },
    { percentage: 0, count: 0 },
    { percentage: 0, count: 0 },
    { percentage: 0, count: 0 },
    { percentage: 0, count: 0 },
  ],
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
      }}
    >
      {count_info.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "0.4rem",
              fontSize: "0.86rem",
              alignItems: "center",
            }}
          >
            <span
              style={{
                width: "1rem",
              }}
            >
              {index}
            </span>
            <Star key={index} weight="fill" />
            {/* progress bar */}
            <div
              style={{
                marginLeft: "0.6rem",
                width: "100%",
                backgroundColor: '#DEDEDE',
                height: '0.6rem',
              }}
            >
              <div
                style={{
                  width: `${item.percentage}%`,
                  height: "100%",
                  backgroundColor: "black",
                }}
              />
            </div>
            <span
              style={{
                width: "1.8rem",
                textAlign: "right",
              }}
            >
              {item.count}
            </span>
          </div>
        );
      })}
    </div>
  );
}
