import { Route, Routes } from "react-router-dom";
import GuestRoutes from "./GuestRoutes";
import AdminRoutes from "./AdminRoutes";

const RoutesContainer = () => {

  return (
    <Routes>
      <Route path={"/*"} element={<GuestRoutes />} />
      <Route path={"/dashboard/*"} element={<AdminRoutes />} />
    </Routes>
  )
}

export default RoutesContainer;