import CustomText from "components/CustomText";
import CarouselBannerSection from "./Sections/CarouselBanner";
import MidBannerSection from "./Sections/MidBanner";
import SubBannerSection from "./Sections/SubBanner";
import TagsSection from "./Sections/Tags";
import { useNavigate } from "react-router-dom";

export default function EditFormHome({
  // banners
  banners,
  setBanners,

  // sub banner
  sub_banners,
  setSubBanners,

  // tags
  tags,
  setTags,
  tags_styles,
  setTagsStyles,

  // mid banners
  mid_banners,
  setMidBanners,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "1vw",
      }}
    >
      <CarouselBannerSection
        {...{
          banners,
          setBanners,
        }}
      />
      <SubBannerSection
        {...{
          sub_banners,
          setSubBanners,
        }}
      />
      <TagsSection
        {...{
          tags,
          setTags,
          tags_styles,
          setTagsStyles,
        }}
      />
      <MidBannerSection
        {...{
          mid_banners,
          setMidBanners,
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "1rem",
          marginBottom: "4rem",
        }}
      >
        <CustomText text="Mais vendidos" size="1.4rem" bold />
        <CustomText
          text="<b>Atenção:</b> os produtos mais vendidos são escolhidos automaticamente pelo sistema conforme a quantidade de vendas."
          size="1rem"
          color="#b50d0d"
        />
      </div>
    </div>
  );
}
