import { IconButton } from "@mui/material";
import { PencilLine, Trash } from "@phosphor-icons/react";
import Swal from "sweetalert2";
import { post } from "utilities/requests";
import TagModal from "./TagModal";

export default function TagItem({
  tag,
  refetch,
  setModalAction,
  setNewTag,
  setOpenTagModal,
}) {
  const handleDelete = async () => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, apagar!",
      cancelButtonText: "Não, cancelar!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("id", tag.id);
        const res = await post("edit-page/home/tag/delete", formData);
        if (res.httpCode === 200) {
          refetch();
          Swal.fire("Apagado!", "A tag foi apagada com sucesso.", "success");
        } else {
          Swal.fire("Erro!", res.message, "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "A tag está seguro :)", "error");
      }
    });
  };

  return (
    <tr key={tag.id}>
      <td>{tag.text}</td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <IconButton
          onClick={() => {
            setModalAction("edit");
            setNewTag(tag);
            setOpenTagModal(true);
          }}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
        >
          <PencilLine />
        </IconButton>
        <IconButton
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
          color="error"
        >
          <Trash />
        </IconButton>
      </td>
    </tr>
  );
}
