import { Box } from "@mui/material";
import { List } from "@phosphor-icons/react";
import ProductCard from "components/ProductCard";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";

export default function ProductsSection({
  categories,
  showModalCategories,
  setShowModalCategories,
}) {
  const [products, setProducts] = useState([]);

  const getProduct = async () => {
    const res = await get("products/list");
    setProducts(res.products);
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <List
            size={28}
            weight="fill"
            onClick={() => setShowModalCategories(!showModalCategories)}
          />
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        />

        <select>
          <option value="crescente">Crescente</option>
          <option value="decrescente">Decrescente</option>
        </select>
      </div>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          },
          gap: "1.8rem",
        }}
      >
        {products.map((product, i) => (
          <ProductCard key={i} {...{ product }} />
        ))}
      </Box>
    </div>
  );
}
