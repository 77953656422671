import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const FAQItem = ({ question, answer, faq }) => {
  const answer_ref = useRef(null);

  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [first_load, setFirstLoad] = useState(true);

  useEffect(() => {
    // get collapse height
    setTimeout(() => {
      setHeight(answer_ref?.current?.scrollHeight);
      setFirstLoad(false);
    }, 1000);
  }, []);

  const handleOpenCollapse = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: faq.card_color_1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1.2rem 2rem",
          gap: "1rem",
          cursor: "pointer",
          backgroundColor: faq.card_color_1,
          "&:hover": {
            filter: "brightness(0.9)",
          },
          borderLeft: open ? `4px solid ${faq.accent_color_1}` : "none",
        }}
        onClick={handleOpenCollapse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            component={"span"}
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
              width: "100%",
              color: open ? faq.accent_color_1 : faq.card_text_color_1,
            }}
          >
            {question}
          </Box>
        </div>

        <span
          style={{
            fontWeight: "bold",
            height: "2rem",
            width: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: "1px solid #d3d3d3",
            backgroundColor: open ? faq.accent_color_1 : "transparent",
            color: open ? "white" : "#737373",
          }}
        >
          <KeyboardArrowUpIcon
            sx={{
              transform: open ? "rotate(0deg)" : "rotate(90deg)",
              transition: "transform 0.5s ease",
            }}
          />
        </span>
      </Box>

      <Box
        ref={answer_ref}
        sx={{
          display: "flex",
          gap: "1rem",
          alignSelf: "flex-end",
          maxHeight: open && !first_load ? height + 22.4 : 0,
          overflow: "hidden",
          transition: "max-height 0.5s ease",
          backdropFilter: "blur(2rem)",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            color: faq.card_text_color_1,
            padding: "1.4rem 2rem",
          }}
        >
          {answer}
        </span>
      </Box>
    </div>
  );
};

export default FAQItem;
