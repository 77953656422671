import { Box } from "@mui/material";

export default function CategoriesAside({ categories }) {
  return (
    <Box
      sx={{
        position: "sticky",
        top: "0",
        width: "20%",
        height: "100%",
        display: {
          xs: "none",
          sm: "flex",
        },
        flexDirection: "column",
      }}
    >
      {categories.map((category, index) => (
        <a
          key={index}
          href="#"
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            padding: "0.8rem 0",
            borderTop: "1px solid #cccccc",
            fontSize: "0.78rem",
          }}
        >
          {category}
        </a>
      ))}
    </Box>
  );
}
