import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";

export default function CustomInput({
  placeholder,
  type,
  value,
  onChange,
  required = false,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      sx={{ width: "100%" }}
      label={placeholder}
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      InputProps={{
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="standard"
      name={placeholder}
      value={value}
      onChange={onChange}
      InputLabelProps={{ shrink: value !== "" }}
      required={required}
      {...props}
    />
    // <FormControl sx={{ width: "100%" }} variant="standard">
    //   <InputLabel htmlFor="standard-adornment-password">
    //     {placeholder}
    //   </InputLabel>
    //   <Input
    //     id="standard-adornment-password"
    //     type={type === "password" ? (showPassword ? "text" : "password") : type}
    //     endAdornment={
    //       type === "password" && (
    //         <InputAdornment position="end">
    //           <IconButton
    //             aria-label="toggle password visibility"
    //             onClick={handleClickShowPassword}
    //             onMouseDown={handleMouseDownPassword}
    //           >
    //             {showPassword ? <VisibilityOff /> : <Visibility />}
    //           </IconButton>
    //         </InputAdornment>
    //       )
    //     }
    //     name={placeholder}
    //     value={value}
    //     onChange={onChange}
    //     required={required}
    //     {...props}
    //   />
    // </FormControl>
  );
}
