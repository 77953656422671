import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomText from "components/CustomText";

const FAQItem = ({ item, faq, setFaq }) => {
  const answer_ref = useRef(null);

  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(0);
  const [first_load, setFirstLoad] = useState(true);

  useEffect(() => {
    // get collapse height
    setTimeout(() => {
      setHeight(answer_ref?.current?.scrollHeight);
      setFirstLoad(false);
    }, 1000);
  }, []);

  const handleOpenCollapse = () => {
    setOpen(!open);
  };

  const handleQuestionChange = (id, value) => {
    let temp = faq.questions;
    temp[id].question = value;
    setFaq({
      ...faq,
      questions: temp,
    });
  };

  const handleAnswerChange = (id, value) => {
    let temp = faq.questions;
    temp[id].answer = value;
    setFaq({
      ...faq,
      questions: temp,
    });
  };

  // const handleQuestionRemove = (id) => {
  //   setFaq({
  //     ...faq,
  //     questions: faq.questions.filter((item, i) => i !== id),
  //   });
  // };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #ebebeb",
        backgroundColor: "#f2f2f2",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // backgroundColor: "var(--background-color1)",
          padding: "0.2rem 1rem",
          gap: "1rem",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#ebebeb",
          },
          borderLeft: open ? "4px solid #23a6f0" : "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <input
            type="text"
            placeholder="Insira a pergunta aqui..."
            style={{
              width: "100%",
              color: open ? "#23a6f0" : "#252b42",
              fontSize: "1rem",

              width: "100%",
              fontSize: "1rem",
              padding: "0.4rem",
              border: "none",
              outline: "none",
            }}
            value={item.question}
            onChange={(e) => handleQuestionChange(item.id, e.target.value)}
          />
        </div>

        <span
          style={{
            fontWeight: "bold",
            // height: "1.4rem",
            width: "fit-content",
            paddingLeft: "0.4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #d3d3d3",
            backgroundColor: open ? "#23a6f0" : "transparent",
            color: open ? "white" : "#737373",
          }}
          onClick={handleOpenCollapse}
        >
          {open ? <span>Esconder</span> : <span>Mostrar</span>}
          <KeyboardArrowUpIcon
            sx={{
              transform: open ? "rotate(0deg)" : "rotate(90deg)",
              transition: "transform 0.5s ease",
            }}
          />
        </span>
      </Box>

      <Box
        ref={answer_ref}
        sx={{
          display: "flex",
          gap: "1rem",
          alignSelf: "flex-end",
          maxHeight: open ? "max-content" : 0,
          overflow: "hidden",
          width: "100%",
          padding: "0 0.4rem 0.4rem 0.4rem",
        }}
      >
        <textarea
          type="text"
          placeholder="Insira a resposta aqui..."
          style={{
            width: "100%",
            fontSize: "1rem",
            padding: "1rem",
            border: "none",
            outline: "none",
          }}
          value={item.answer}
          onChange={(e) => handleAnswerChange(item.id, e.target.value)}
        />
      </Box>
    </div>
  );
};

export default FAQItem;
