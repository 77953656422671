import { toast } from "react-toastify";
import FAQItem from "./FAQItem";

export default function AdminFAQSection({ faq, setFaq }) {
  const handleQuestionAdd = () => {
    setFaq({
      ...faq,
      questions: [
        ...faq.questions,
        {
          id: faq.questions.length,
          question: "",
          answer: "",
        },
      ],
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <label
        style={{
          textTransform: "uppercase",
          padding: "0.4rem 1rem",
          backgroundColor: "#333333",
          color: "#FFFFFF",
          cursor: "pointer",
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          width: "fit-content",
        }}
        onClick={() => {
          handleQuestionAdd();
          toast.success("Pergunta adicionada com sucesso!");
        }}
      >
        Adicionar item
      </label>

      {faq.questions.map((item, index) => {
        return (
          <FAQItem
            {...{
              item,
              faq,
              setFaq,
            }}
          />
        );
      })}
    </div>
  );
}
