import { Container } from "@mui/material";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { checkBase64 } from "utilities/helpers";
import { get } from "utilities/requests";
import { STORAGE_URL } from "utilities/variables";

export default function SobreNosPage({
  preview_mode = false,
  preview_content,
}) {
  const [page_content, setPageContent] = useState({});

  const getPageContent = async () => {
    const res = await get("get-page/sobre-nos/all");
    setPageContent(res);
  };

  useEffect(() => {
    if (preview_mode) {
      setPageContent(preview_content);
    } else {
      getPageContent();
    }
  }, []);

  useEffect(() => {
    setPageContent(preview_content);
  }, [preview_content]);

  return (
    <div>
      <div
        style={{
          position: "relative",
          marginBottom: "2rem",
        }}
      >
        <CustomText
          text="Sobre nós"
          variant="h1"
          color="white"
          style={{
            position: "absolute",
            whiteSpace: "nowrap",
            zIndex: 4,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "7rem",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        />

        <img
          src={checkBase64(page_content?.banner?.image_path)}
          alt="Banner categoria"
          style={{
            width: "100%",
            height: "28rem",
            objectFit: "cover",
            zIndex: 1,
          }}
        />
      </div>

      <Container maxWidth="lg">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: page_content?.main_text,
            }}
          />
        </div>
      </Container>
    </div>
  );
}
