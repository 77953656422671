import { post } from "utilities/requests";
import validate from "./form_validator";
import { toast } from "react-toastify";

export const handleAddProduct = async ({
  header_info,
  header_images,
  description,
  product_comparison,
  nutritional_info,
  faq,
}) => {
  let error = validate({
    header_images,
    description,
    product_comparison,
    nutritional_info,
    faq,
  });

  if (error) {
    toast.warn(error);
    return;
  }

  let form = new FormData();

  // secao 1
  form.append("header_info", JSON.stringify(header_info));
  for (let i = 0; i < header_images.length; i++) {
    form.append("header_images[]", header_images[i].image_file);
  }

  // secao 2
  // drop image files
  let temp_description = { ...description };
  delete temp_description.top_image;
  delete temp_description.bottom_image;
  delete temp_description.background_image;

  form.append("description", JSON.stringify(temp_description));
  form.append("description_top_image", description.top_image.image_file);
  form.append("description_bottom_image", description.bottom_image.image_file);
  form.append(
    "description_background_image",
    description.background_image.image_file
  );

  // secao 3
  // drop image files
  let temp_product_comparison = { ...product_comparison };
  delete temp_product_comparison.table;

  form.append("product_comparison", JSON.stringify(temp_product_comparison));
  form.append("product_comparison_table", product_comparison.table.image_file);

  // secao 4
  // drop image files
  let temp_nutritional_info = { ...nutritional_info };
  delete temp_nutritional_info.table;

  form.append("nutritional_info", JSON.stringify(temp_nutritional_info));
  form.append("nutritional_info_table", nutritional_info.table.image_file);

  // secao 5
  form.append("faq", JSON.stringify(faq));
  for (let i = 0; i < faq.questions.length; i++) {
    form.append("faq_questions[]", JSON.stringify(faq.questions[i]));
  }

  // inspect form data
  let form_data = {};
  for (let key of form.keys()) {
    form_data[key] = form.get(key);
  }
  console.log(form_data);
  // return;

  const toast_id = toast.loading("Adicionando produto...");

  const res = await post(`products/add`, form);

  if (res.httpCode === 200) {
    toast.update(toast_id, {
      render: "Produto adicionado com sucesso!",
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
    setTimeout(() => {
      window.location.href = "/dashboard/produtos";
    }, 3000);
  } else {
    toast.update(toast_id, {
      render: res.message || "Erro ao adicionar produto",
      type: "error",
      isLoading: false,
      autoClose: 4000,
    });
    return false;
  }
};

export const handleEditProduct = async ({
  id,
  header_info,
  header_images,
  deleted_header_images,
  description,
  product_comparison,
  nutritional_info,
  faq,
}) => {
  let error = validate({
    header_images,
    description,
    product_comparison,
    nutritional_info,
    faq,
  });

  if (error) {
    toast.warn(error);
    return;
  }

  let form = new FormData();

  form.append("id", id);

  // secao 1
  form.append("header_info", JSON.stringify(header_info));
  for (let i = 0; i < header_images.length; i++) {
    if (header_images[i].image_file) {
      form.append("header_images[]", header_images[i].image_file);
    }
  }
  form.append("deleted_header_images", JSON.stringify(deleted_header_images));

  // secao 2
  // drop image files
  let temp_description = { ...description };
  delete temp_description.top_image;
  delete temp_description.bottom_image;
  delete temp_description.background_image;

  form.append("description", JSON.stringify(temp_description));
  if (description.top_image.image_file) {
    form.append("description_top_image", description.top_image.image_file);
  }
  if (description.bottom_image.image_file) {
    form.append(
      "description_bottom_image",
      description.bottom_image.image_file
    );
  }
  if (description.background_image) {
    form.append(
      "description_background_image",
      description.background_image.image_file
    );
  }

  // secao 3
  // drop image files
  let temp_product_comparison = { ...product_comparison };
  delete temp_product_comparison.table;

  form.append("product_comparison", JSON.stringify(temp_product_comparison));
  if (product_comparison.table.image_file) {
    form.append(
      "product_comparison_table",
      product_comparison.table.image_file
    );
  }

  // secao 4
  // drop image files
  let temp_nutritional_info = { ...nutritional_info };
  delete temp_nutritional_info.table;

  form.append("nutritional_info", JSON.stringify(temp_nutritional_info));
  if (nutritional_info.table.image_file) {
    form.append("nutritional_info_table", nutritional_info.table.image_file);
  }

  // secao 5
  form.append("faq", JSON.stringify(faq));
  for (let i = 0; i < faq.questions.length; i++) {
    form.append("faq_questions[]", JSON.stringify(faq.questions[i]));
  }

  // inspect form data
  let form_data = {};
  for (let key of form.keys()) {
    form_data[key] = form.get(key);
  }
  console.log(form_data);
  // return;

  const toast_id = toast.loading("Editando produto...");

  const res = await post(`products/update`, form);

  if (res.httpCode === 200) {
    toast.update(toast_id, {
      render: "Produto editado com sucesso!",
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
    setTimeout(() => {
      window.location.href = "/dashboard/produtos";
    }, 3000);
  } else {
    toast.update(toast_id, {
      render: res.message || "Erro ao editar produto",
      type: "error",
      isLoading: false,
      autoClose: 4000,
    });
  }
};
