export default {
  only_text: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "textAlign",
    "colorPicker",
    "link",
    "history",
  ],
  full: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "emoji",
    "image",
    "remove",
    "history",
  ],
};

// export const only_text = {
//   options: [
//     "inline",
//     "blockType",
//     "fontSize",
//     "fontFamily",
//     "textAlign",
//     "link",
//     "history",
//   ],
// };

// export const full = {
//   options: [
//     "inline",
//     "blockType",
//     "fontSize",
//     "fontFamily",
//     "list",
//     "textAlign",
//     "colorPicker",
//     "link",
//     "embedded",
//     "emoji",
//     "image",
//     "remove",
//     "history",
//   ],
// };
