import AdminAttachImageProduct from "./sections/HeaderInfo/AttachImage";
import HeaderInfoSection from "./sections/HeaderInfo";
import StylingSections from "./sections/HeaderInfo/StylingSections";
import AccordionSection from "./Accordion";
import DescriptionSection from "./sections/Descricao";
import ProductComparisonSection from "./sections/ProductComparison";
import AdminNutritionalInfoSection from "./sections/NutritionalInfo";
import AdminFAQSection from "./sections/FAQ";

export default function EditFormProdutos({
  // secao 1
  header_info,
  setHeaderInfo,
  header_images,
  setHeaderImages,
  deleted_header_images,
  setDeletedHeaderImages,

  // secao 2
  description,
  setDescription,

  // secao 3
  product_comparison,
  setProductComparison,

  // secao 4
  nutritional_info,
  setNutritionalInfo,

  // secao 5
  faq,
  setFaq,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        gap: "1rem",
      }}
    >
      {/* secao 1 */}
      <AccordionSection title="Seção 1 - Informações do Produto">
        <HeaderInfoSection
          {...{
            header_info,
            setHeaderInfo,
          }}
        />
        <StylingSections
          {...{
            section_state: header_info,
            setSectionState: setHeaderInfo,
          }}
          has_background_color_1
          has_background_color_2
          has_primary_text_color
          has_secondary_text_color
          has_accent_color_1
        />
        <AdminAttachImageProduct
          {...{
            header_images,
            setHeaderImages,
            deleted_header_images,
            setDeletedHeaderImages,
          }}
        />
      </AccordionSection>

      {/* secao 2 */}
      <AccordionSection title="Seção 2 - Descrição">
        <DescriptionSection
          {...{
            description,
            setDescription,
          }}
        />
        <StylingSections
          {...{
            section_state: description,
            setSectionState: setDescription,
          }}
          has_background_color_1
          has_background_color_2
        />
      </AccordionSection>

      {/* secao 3 */}
      <AccordionSection title="Seção 3 - Comparação de produtos">
        <ProductComparisonSection
          {...{
            product_comparison,
            setProductComparison,
          }}
        />
        <StylingSections
          {...{
            section_state: product_comparison,
            setSectionState: setProductComparison,
          }}
          has_background_color_1
          has_background_color_2
        />
      </AccordionSection>

      {/* secao 4 */}
      <AccordionSection title="Seção 4 - Informações nutricionais">
        <AdminNutritionalInfoSection
          {...{
            nutritional_info,
            setNutritionalInfo,
          }}
        />
        <StylingSections
          {...{
            section_state: nutritional_info,
            setSectionState: setNutritionalInfo,
          }}
          has_background_color_1
          has_background_color_2
          has_primary_text_color
        />
      </AccordionSection>

      {/* secao 5 */}
      <AccordionSection title="Seção 5 - Perguntas frequentes">
        <AdminFAQSection
          {...{
            faq,
            setFaq,
          }}
        />
        <StylingSections
          {...{
            section_state: faq,
            setSectionState: setFaq,
          }}
          has_background_color_1
          has_background_color_2
          has_primary_text_color
          has_accent_color_1
          has_card_color_1
          has_card_text_color_1
        />
      </AccordionSection>
    </div>
  );
}
