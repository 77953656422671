import {
  CreditCard,
  House,
  MagnifyingGlass,
  PresentationChart,
  ShoppingCart,
  UserList,
} from "@phosphor-icons/react";
import SidebarItem from "./SidebarItem";

export default function AdminMenu({ shrunk }) {
  return (
    <>
      <SidebarItem
        icon={<PresentationChart />}
        title="Dashboard"
        href="/"
        {...{ shrunk }}
      />

      <SidebarItem
        icon={<ShoppingCart />}
        title="Produtos"
        href="/produtos"
        {...{ shrunk }}
      />

      {/* Usuários */}
      <SidebarItem icon={<CreditCard />} title="Editar páginas" {...{ shrunk }}>
        <SidebarItem
          icon={<House />}
          title="Home"
          href="/editar-home"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<MagnifyingGlass />}
          title="Pesquisar"
          href="/editar-pesquisar"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<UserList />}
          title="Sobre nós"
          href="/editar-sobre-nos"
          {...{ shrunk }}
        />
      </SidebarItem>
    </>
  );
}
