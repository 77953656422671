import CustomText from "components/CustomText";
import { AnimatePresence, Reorder } from "framer-motion";
import { useEffect, useState } from "react";
import BannerItem from "./BannerItem";
import SaveOrderButton from "components/SaveOrderButton";
import { useMutation } from "react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomButton from "components/CustomButton";
import { get, post } from "utilities/requests";
import { toast } from "react-toastify";

export default function CarouselBannerSection({ banners, setBanners }) {
  const getBanners = async () => {
    const res = await get("get-page/home/banners");
    setBanners(res.banners);
  };

  const handleAddBanner = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const res = await post("edit-page/home/banner", formData);

    if (res.httpCode === 200) {
      toast.success("Banner adicionado com sucesso");
      getBanners();
    } else {
      toast.error(res.message || "Erro ao adicionar banner");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <CustomText text="Banner rotativo" size="1.4rem" bold />

        <input
          id="upload_banner_button"
          type="file"
          onChange={handleAddBanner}
          accept="image/*"
          hidden
        />
        <label
          htmlFor="upload_banner_button"
          style={{
            textTransform: "uppercase",
            padding: "0.4rem 1rem",
            backgroundColor: "#333333",
            color: "#FFFFFF",
            cursor: "pointer",
            textAlign: "center",
            width: "fit-content",
          }}
        >
          Adicionar banner
        </label>
      </div>

      <Reorder.Group axis={"y"} onReorder={setBanners} values={banners}>
        <table
          style={{
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Imagem
              </th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {banners.map((banner, index) => (
              <BannerItem key={index} {...{ banner, refetch: getBanners }} />
            ))}
            {banners.length === 0 && (
              <td
                colSpan={2}
                style={{
                  textAlign: "center",
                  paddingTop: "1rem",
                }}
              >
                Não há banners cadastrados
              </td>
            )}
          </tbody>
        </table>
      </Reorder.Group>

      {/* change order confirmation */}
      {/* <AnimatePresence>
        {JSON.stringify(banners) != JSON.stringify(tempBanners) && (
          <SaveOrderButton
            onClick={() => {
              orderMutation.mutate();
              setBanners([tempBanners, tempBanners]);
            }}
            onCancel={() => setBanners([banners, banners])}
          />
        )}
      </AnimatePresence> */}

      <hr
        style={{
          display: "flex",
          maxWidth: "46rem",
          width: "100%",
          marginBottom: "4rem",
          backgroundColor: "#cccccc",
          border: "none",
          height: "1px",
        }}
      />
    </div>
  );
}
