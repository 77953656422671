import { Box, Container } from "@mui/material";
import CustomText from "components/CustomText";
import { checkBase64 } from "utilities/helpers";

export default function AboutProductSection({ description }) {
  return (
    <div
      style={{
        background: description.background_color_2
          ? `linear-gradient(180deg, ${description.background_color_1} 0%, ${description.background_color_2} 100%)`
          : description.background_color_1,
        padding: "4rem 0",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      {description.background_image?.image && (
        <img
          src={checkBase64(description.background_image?.image)}
          alt="Fundo"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            zIndex: 1,
            opacity: description.background_opacity,
          }}
        />
      )}
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          zIndex: 2,
          height: "max-content",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "row",
              },
              gap: "8vw",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "1.8rem",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: description.top_text,
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
              }}
            >
              <img
                src={checkBase64(description.top_image?.image)}
                alt="Product"
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "1/1",
                  objectFit: "cover",
                }}
              />
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "row-reverse",
              },
              gap: "8vw",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "1.8rem",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: description.bottom_text,
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
              }}
            >
              <img
                src={checkBase64(description.bottom_image?.image)}
                alt="Product"
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "1/1",
                  objectFit: "cover",
                }}
              />
            </div>
          </Box>
        </div>
      </Container>
    </div>
  );
}
