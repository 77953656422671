import { Star } from "@phosphor-icons/react";
import CustomText from "components/CustomText";

export default function RateSection({ header_info }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        {[...Array(5)].map((_, index) => {
          return (
            <Star
              key={index}
              size={16}
              style={{
                marginRight: "0.2rem",
                color: header_info.accent_color_1,
              }}
              weight="fill"
            />
          );
        })}
      </div>

      <CustomText
        text={"1 Avaliação(ões)"}
        size="0.9rem"
        color={header_info.primary_text_color}
      />
    </div>
  );
}
