import BannerSection from "./Sections/Banner";
import MainTextSection from "./Sections/MainText";

export default function EditFormSobreNos({
  banner,
  setBanner,
  main_text,
  setMainText,
  handleChangeMainText,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "1vw",
      }}
    >
      <BannerSection {...{ banner, setBanner }} />

      <hr
        style={{
          display: "flex",
          maxWidth: "46rem",
          width: "100%",
          marginTop: "2rem",
          backgroundColor: "#cccccc",
          border: "none",
          height: "1px",
        }}
      />

      <br />

      <MainTextSection
        {...{
          main_text,
          setMainText,
          handleChangeMainText,
        }}
      />
    </div>
  );
}
