import Navbar from "components/Navbar";
import LoginPage from "pages/Auth/Login";
import HomePage from "pages/Home";
import PesquisarPage from "pages/Pesquisar";
import SobreNosPage from "pages/SobreNos";
import VerProdutoPage from "pages/VerProduto";
import { Route, Routes } from "react-router-dom";

const GuestRoutes = () => {
  return (
    <>
      <Navbar />

      <Routes>
        {/*  */}
        <Route path={"/*"} element={<HomePage />} />
        <Route path={"/pesquisar"} element={<PesquisarPage />} />
        <Route path={"/produto/:name"} element={<VerProdutoPage />} />
        <Route path={"/sobre-nos"} element={<SobreNosPage />} />

        {/* auth */}
        <Route path={"/login"} element={<LoginPage />} />
      </Routes>

      {/* <Footer /> */}
    </>
  );
};

export default GuestRoutes;
