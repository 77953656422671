import { Container } from "@mui/material";
import { ShoppingCart, UserCircle } from "@phosphor-icons/react";
import GlobalCart from "components/GlobalCart";
import { useNavigate } from "react-router-dom";

const navbar_links = [
  {
    name: "Início",
    url: "/",
  },
  {
    name: "Produtos",
    url: "/produtos",
  },
  {
    name: "Prescritor",
    url: "/prescritor",
  },
  {
    name: "Contato",
    url: "/contato",
  },
];

export default function Navbar() {
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 5,
        width: "100%",
      }}
    >
      {/* top navbar */}
      <TopNavbar />

      {/* main navbar */}
      <MainNavbar />
    </div>
  );
}

function MainNavbar() {
  let navigate = useNavigate();
  const token = localStorage.getItem("token");

  const circle_icon_style = {
    backgroundColor: "#dedede",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.5rem",
    width: "2rem",
    height: "2rem",
    color: "#4d4d4d",
    cursor: "pointer",
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderBottom: "1px solid #e6e6e6",
      }}
    >
      <Container maxWidth="lg">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem 0",
            position: "relative",
          }}
        >
          <div>
            <a href="#">Logo</a>
          </div>

          {/* absolute centered */}
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              gap: "4vw",
            }}
          >
            {navbar_links.map((link, index) => (
              <a key={index} href={link.url}>
                {link.name}
              </a>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.4rem",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (token === null) {
                  return navigate("/login");
                } else {
                  return navigate("/perfil");
                }
              }}
            >
              {token === null && (
                <span
                  style={{
                    color: "#4d4d4d",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                  }}
                >
                  Entrar
                </span>
              )}
              <div style={circle_icon_style}>
                <UserCircle />
              </div>
            </div>
            <div style={circle_icon_style}>
              <GlobalCart />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

function TopNavbar() {
  return (
    <div
      style={{
        backgroundColor: "#181818",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            color: "white",
            fontSize: "0.8rem",
          }}
        >
          <a
            href="#"
            style={{
              boxShadow: "inset -1px 0 0 0 #454545",
              padding: "0.7rem 1.4rem",
              paddingLeft: 0,
            }}
          >
            Whatsapp
          </a>
          <a
            href="#"
            style={{
              boxShadow: "inset -1px 0 0 0 #454545",
              padding: "0.7rem 1.4rem",
            }}
          >
            Instagram
          </a>
        </div>

        {/* test */}
        <div
          style={{
            display: "flex",
            color: "white",
            fontSize: "0.8rem",
          }}
        >
          <a
            href="/"
            style={{
              boxShadow: "inset -1px 0 0 0 #454545",
              padding: "0.7rem 1.4rem",
              paddingLeft: 0,
            }}
          >
            Home
          </a>
          <a
            href="pesquisar"
            style={{
              boxShadow: "inset -1px 0 0 0 #454545",
              padding: "0.7rem 1.4rem",
            }}
          >
            Pesquisar
          </a>
          {/* <a
            href="produto"
            style={{
              boxShadow: "inset -1px 0 0 0 #454545",
              padding: "0.7rem 1.4rem",
            }}
          >
            Produto
          </a> */}
          <a
            href="sobre-nos"
            style={{
              boxShadow: "inset -1px 0 0 0 #454545",
              padding: "0.7rem 1.4rem",
            }}
          >
            Sobre nos
          </a>
        </div>
      </Container>
    </div>
  );
}
