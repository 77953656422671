import { Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import FAQItem from "./FAQItem";
import CustomText from "components/CustomText";

export default function FAQProductSection({ faq }) {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "max-content",
        gap: "2rem",
        zIndex: 1,
        alignItems: "center",
        padding: "4rem 0",
        background: faq.background_color_2
          ? `linear-gradient(180deg, ${faq.background_color_1} 0%, ${faq.background_color_2} 100%)`
          : faq.background_color_1,
      }}
    >
      <CustomText
        text={"Perguntas Frequentes"}
        variant={"h3"}
        size={"1.94rem"}
        justify={"center"}
        bold
        style={{
          textTransform: "uppercase",
          marginBottom: "1rem",
        }}
        color={faq.primary_text_color}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "54rem",
        }}
      >
        {faq.questions.map((answer, index) => (
          <FAQItem
            key={index}
            question={answer.question}
            answer={answer.answer}
            faq={faq}
          />
        ))}
      </div>
    </Container>
  );
}
