import BannerSection from "./Sections/Banner";

export default function EditFormPesquisar() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        padding: "1vw",
      }}
    >
      <BannerSection />
    </div>
  );
}
