import { Box } from "@mui/material";
import { CaretLeft, Power } from "@phosphor-icons/react";
import CustomText from "components/CustomText";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { handleLogout } from "utilities/helpers";

export default function SidebarHeader({ shrunk, handleShrunk }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: shrunk ? "row-reverse" : "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: shrunk ? "0 0.6rem" : "0.6rem",
        gap: "0.6rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: shrunk ? "center" : "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {!shrunk && (
          <Box
            sx={{
              cursor: "pointer",
              padding: "0.3rem 0.5rem",
              borderRadius: "0.5rem",
              display: "flex",
              alignItems: "center",
              gap: "0.4rem",
              ":hover": {
                backgroundColor: "#E0E0E0",
              },
            }}
            onClick={() => {
              swal({
                title: "Sair?",
                text: "Você será deslogado do sistema!",
                icon: "warning",
                buttons: ["Cancelar", "Sair"],
                dangerMode: true,
              }).then((willLogout) => {
                if (willLogout) {
                  handleLogout();
                }
              });
            }}
          >
            <Power />
            {shrunk ? null : <span>Sair</span>}
          </Box>
        )}
        <Box
          sx={{
            cursor: "pointer",
            padding: "0.3rem 0.5rem",
            borderRadius: "0.5rem",
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
            ":hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={handleShrunk}
        >
          <CaretLeft
            style={{
              transform: shrunk ? "scale(-1, 1)" : "scale(1, 1)",
              transition: "0.3s ease-in-out",
            }}
          />
          {shrunk ? null : <span>Encolher</span>}
        </Box>
      </div>

      <div
        style={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: shrunk ? "0" : "0.8rem",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            cursor: "pointer",
            transition: "0.3s ease-in-out",
            ":hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={() => navigate("/admin/perfil")}
        >
          <img
            src={"https://picsum.photos/200"}
            alt="Usuário"
            style={{
              width: "3rem",
              borderRadius: "0.6rem",
              aspectRatio: "1/1",
              margin: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              maxWidth: shrunk ? "0" : "20rem",
              opacity: shrunk ? "0" : "1",
              overflow: "hidden",
              transition: "0.3s ease-in-out",
            }}
          >
            <span
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                color: "var(--text-color1)",
              }}
            >
              Meu perfil
            </span>
            <CustomText
              variant="span"
              text={user?.name}
              lines={1}
              style={{
                fontSize: "0.9rem",
              }}
            />
          </div>
        </Box>
      </div>
    </div>
  );
}
