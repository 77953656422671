import CustomText from "components/CustomText";
import { PopoverColorPicker } from "components/PopoverColorPicker";
import { HexColorInput } from "react-colorful";

const color_input_style = {
  borderRadius: "0.3rem",
  padding: "0.2rem 0.5rem",
  fontSize: "1rem",
  textAlign: "center",
  width: "5rem",
};

export default function StylingSections({
  section_state,
  setSectionState,

  // check if section has this property
  has_background_color_1 = false,
  has_background_color_2 = false,
  has_primary_text_color = false,
  has_secondary_text_color = false,
  has_accent_color_1 = false,
  has_card_color_1 = false,
  has_card_text_color_1 = false,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <CustomText text="Estilização" size="1rem" bold />

      {/* fundo 1 */}
      <div
        style={{
          display: has_background_color_1 ? "flex" : "none",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <CustomText text="Fundo 1" size="1rem" />
        <PopoverColorPicker
          color={section_state.background_color_1}
          onChange={(color) =>
            setSectionState({ ...section_state, background_color_1: color })
          }
        />
        <HexColorInput
          color={section_state.background_color_1}
          onChange={(color) =>
            setSectionState({ ...section_state, background_color_1: color })
          }
          placeholder="HEX"
          style={color_input_style}
        />
      </div>

      {/* fundo 2 */}
      <div
        style={{
          display: has_background_color_2 ? "flex" : "none",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <CustomText text="Fundo 2" size="1rem" />
        <PopoverColorPicker
          color={section_state.background_color_2}
          onChange={(color) =>
            setSectionState({ ...section_state, background_color_2: color })
          }
        />
        <HexColorInput
          color={section_state.background_color_2}
          onChange={(color) =>
            setSectionState({ ...section_state, background_color_2: color })
          }
          placeholder="HEX"
          style={color_input_style}
        />
      </div>

      {/* cor do texto principal */}
      <div
        style={{
          display: has_primary_text_color ? "flex" : "none",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <CustomText text="Texto principal" size="1rem" />
        <PopoverColorPicker
          color={section_state.primary_text_color}
          onChange={(color) =>
            setSectionState({ ...section_state, primary_text_color: color })
          }
        />
        <HexColorInput
          color={section_state.primary_text_color}
          onChange={(color) =>
            setSectionState({ ...section_state, primary_text_color: color })
          }
          placeholder="HEX"
          style={color_input_style}
        />
      </div>

      {/* cor do texto secundario */}
      <div
        style={{
          display: has_secondary_text_color ? "flex" : "none",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <CustomText text="Texto secundario" size="1rem" />
        <PopoverColorPicker
          color={section_state.secondary_text_color}
          onChange={(color) =>
            setSectionState({ ...section_state, secondary_text_color: color })
          }
        />
        <HexColorInput
          color={section_state.secondary_text_color}
          onChange={(color) =>
            setSectionState({ ...section_state, secondary_text_color: color })
          }
          placeholder="HEX"
          style={color_input_style}
        />
      </div>

      {/* cor de destaque */}
      <div
        style={{
          display: has_accent_color_1 ? "flex" : "none",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <CustomText text="Cor de destaque" size="1rem" />
        <PopoverColorPicker
          color={section_state.accent_color_1}
          onChange={(color) =>
            setSectionState({ ...section_state, accent_color_1: color })
          }
        />
        <HexColorInput
          color={section_state.accent_color_1}
          onChange={(color) =>
            setSectionState({ ...section_state, accent_color_1: color })
          }
          placeholder="HEX"
          style={color_input_style}
        />
      </div>

      {/* cor do cartão */}
      <div
        style={{
          display: has_card_color_1 ? "flex" : "none",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <CustomText text="Cor do cartão" size="1rem" />
        <PopoverColorPicker
          color={section_state.card_color_1}
          onChange={(color) =>
            setSectionState({ ...section_state, card_color_1: color })
          }
        />
        <HexColorInput
          color={section_state.card_color_1}
          onChange={(color) =>
            setSectionState({ ...section_state, card_color_1: color })
          }
          placeholder="HEX"
          style={color_input_style}
        />
      </div>

      {/* cor do texto do cartão */}
      <div
        style={{
          display: has_card_text_color_1 ? "flex" : "none",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <CustomText text="Cor do texto do cartão" size="1rem" />
        <PopoverColorPicker
          color={section_state.card_text_color_1}
          onChange={(color) =>
            setSectionState({ ...section_state, card_text_color_1: color })
          }
        />
        <HexColorInput
          color={section_state.card_text_color_1}
          onChange={(color) =>
            setSectionState({ ...section_state, card_text_color_1: color })
          }
          placeholder="HEX"
          style={color_input_style}
        />
      </div>
    </div>
  );
}
