import { IconButton } from "@mui/material";
import style from "./style.module.css";
import { Eye, PencilLine, Trash } from "@phosphor-icons/react";
import { STORAGE_URL } from "utilities/variables";
import Swal from "sweetalert2";
import { post } from "utilities/requests";
import { useNavigate } from "react-router-dom";

export default function AdminProductItem({ product, refetch }) {
  const navigate = useNavigate();

  const handleProductClick = () => {
    window.open(`/produto/${product.name.toLowerCase().replaceAll(" ", "-")}`);
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, apagar!",
      cancelButtonText: "Não, cancelar!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("id", product.id);
        const res = await post("products/delete", formData);
        if (res.httpCode === 200) {
          refetch();
          Swal.fire("Apagado!", "A tag foi apagada com sucesso.", "success");
        } else {
          Swal.fire("Erro!", res.message, "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "A tag está seguro :)", "error");
      }
    });
  };

  return (
    <tr>
      <td className={style.tdImage}>
        <img
          src={
            product.header_info.header_images !== undefined &&
            STORAGE_URL + product.header_info.header_images[0]?.image
          }
          alt="Imagem do produto"
        />
      </td>
      <td>{product.header_info.name}</td>
      <td>{product.header_info.price}</td>
      <td>{product.header_info.quantity}</td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <IconButton
          onClick={() => navigate("editar/" + product.id)}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
        >
          <PencilLine />
        </IconButton>
        <IconButton
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
          color="error"
        >
          <Trash />
        </IconButton>
        <IconButton
          onClick={handleProductClick}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
          color="secondary"
        >
          <Eye />
        </IconButton>
      </td>
    </tr>
  );
}
