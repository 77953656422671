import { Box } from "@mui/material";
import ProductCard from "components/ProductCard";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";

export default function BestSellers() {
  const [products, setProducts] = useState([]);

  const getProduct = async () => {
    const res = await get("products/list");
    setProducts(res.products);
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3
        style={{
          textTransform: "uppercase",
          fontSize: "1.9rem",
          fontWeight: "bold",
          textAlign: "center",
          margin: "2rem 0 1rem 0",
        }}
      >
        Mais Vendidos
      </h3>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          },
          gap: "1.8rem",
        }}
      >
        {products &&
          products.map((product, i) => (
            <ProductCard key={i} {...{ product }} />
          ))}
      </Box>
    </div>
  );
}
