import { XCircle } from "@phosphor-icons/react";
import CustomModal from "components/CustomModal";

export default function MobileCategoriesAside({
  showModalCategories,
  setShowModalCategories,
  categories,
}) {
  return (
    <CustomModal
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        color: "black",
        fontSize: "1.4rem",
        top: "0",
        transition: "all 0.2s ease-in-out",
      }}
      visible={showModalCategories}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <span>Categorias</span>
        <XCircle
          size={32}
          style={{
            cursor: "pointer",
          }}
          onClick={() => setShowModalCategories(false)}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 2rem",
        }}
      >
        {categories.map((category, index) => (
          <a
            key={index}
            href="#"
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              padding: "0.8rem 0",
              borderTop: "1px solid #cccccc",
              fontSize: "1rem",
            }}
          >
            {category}
          </a>
        ))}
      </div>
    </CustomModal>
  );
}
