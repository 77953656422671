export default function CustomButton({
  text = "insira o text",
  onClick = () => {},
  style,
  disabled = false,
  icon = null,
  bgColor = "#333333",
  textColor = "#FFFFFF",
}) {
  return (
    <button
      style={{
        textTransform: "uppercase",
        padding: "1.2rem",
        backgroundColor: bgColor,
        color: textColor,
        fontWeight: "bold",
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && icon}
      <span>{text}</span>
    </button>
  );
}
