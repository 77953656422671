import HomePage from "pages/Home";
import { useEffect, useState } from "react";
import EditFormHome from "./EditForm";
import CustomText from "components/CustomText";
import { CaretRight, House } from "@phosphor-icons/react";
import { get } from "utilities/requests";

export default function EditarPaginasHome() {
  const [show_preview, setShowPreview] = useState(true);

  // section states
  // banners
  const [banners, setBanners] = useState([]);

  // sub banners
  const [sub_banners, setSubBanners] = useState([]);

  // tags
  const [tags, setTags] = useState([]);
  const [tags_styles, setTagsStyles] = useState({
    background_color_1: "#FFFFFF",
    background_color_2: "#FFFFFF",
    primary_text_color: "#000000",
  });

  // mid banners
  const [mid_banners, setMidBanners] = useState([]);

  const getPageContent = async () => {
    const res = await get("get-page/home/all");
    setBanners(res.banners);
    setSubBanners(res.sub_banners);
    setTags(res.tags);
    setTagsStyles(res.tags_styles);
    setMidBanners(res.mid_banners);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 968) {
        setShowPreview(false);
      } else {
        setShowPreview(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getPageContent();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* form */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: show_preview ? "60%" : "100%",
          transition: "500ms ease",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.4rem",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.4rem",
                fontSize: "1.4rem",
                alignItems: "center",
              }}
            >
              <House weight="fill" />
              <CustomText
                text="Editar página inicial"
                size="1.4rem"
                bold
                style={{
                  margin: "1rem 0",
                }}
              />
            </div>
            <label
              style={{
                textTransform: "uppercase",
                padding: "0.4rem 1rem",
                backgroundColor: "#333333",
                color: "#FFFFFF",
                cursor: "pointer",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => {
                setShowPreview(!show_preview);
              }}
            >
              {show_preview ? (
                <>
                  <CaretRight weight="fill" />
                  <span>Esconder preview</span>
                </>
              ) : (
                <>
                  <CaretRight
                    weight="fill"
                    style={{
                      transform: "rotate(180deg)",
                      fontSize: "1.4rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  <span>Mostrar preview</span>
                </>
              )}
            </label>
          </div>
          <CustomText
            text="<b>Atenção:o preview pode não ser 100% fiel ao resultado final.</b>  Ex: A responsividade das tags usa como base a tela completa para ser responsivo, e não o tamanho do preview."
            size="1rem"
            color="#4d4d4d"
          />
        </div>
        <EditFormHome
          {...{
            // banners
            banners,
            setBanners,

            // sub banner
            sub_banners,
            setSubBanners,

            // tags
            tags,
            setTags,
            tags_styles,
            setTagsStyles,

            // mid banners
            mid_banners,
            setMidBanners,
          }}
        />
      </div>

      {/* preview */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: show_preview ? "100%" : "0%",
          overflowY: "auto",
          borderLeft: "1px solid #ccc",
          transition: "500ms ease",
        }}
      >
        <HomePage
          {...{
            preview_mode: true,
            preview_content: {
              banners,
              sub_banners,
              tags,
              tags_styles,
              mid_banners,
            },
          }}
        />
      </div>
    </div>
  );
}
