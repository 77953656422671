import { IconButton } from "@mui/material";
import { ArrowsVertical, Trash } from "@phosphor-icons/react";
import { Reorder, useDragControls } from "framer-motion";
import Swal from "sweetalert2";
import { post } from "utilities/requests";
import { STORAGE_URL } from "utilities/variables";

export default function SubBannerItem({ banner, refetch }) {
  const dragControls = useDragControls();

  const handleDelete = async () => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, apagar!",
      cancelButtonText: "Não, cancelar!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("id", banner.id);
        const res = await post("edit-page/home/sub-banner/delete", formData);
        if (res.httpCode === 200) {
          refetch();
          Swal.fire(
            "Apagado!",
            "Seu sub banner foi apagado com sucesso.",
            "success"
          );
        } else {
          Swal.fire("Erro!", res.message, "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Seu sub banner está seguro :)", "error");
      }
    });
  };

  return (
    <Reorder.Item
      value={banner}
      dragListener={false}
      dragControls={dragControls}
      as="tr"
    >
      <td>
        <img
          src={STORAGE_URL + banner.image_path}
          alt={"Banner"}
          style={{
            width: "10rem",
            aspectRatio: "5/3",
            objectFit: "cover",
          }}
        />
      </td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <IconButton
          onPointerDown={(e) => dragControls.start(e)}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
        >
          <ArrowsVertical />
        </IconButton>
        <IconButton
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            touchAction: "pan-x",
          }}
          color="error"
        >
          <Trash />
        </IconButton>
      </td>
    </Reorder.Item>
  );
}
