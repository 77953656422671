import { ExpandCircleDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CustomText from "components/CustomText";

export default function AccordionSection({ title, children }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandCircleDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <CustomText text={title} size="1.2rem" bold />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
