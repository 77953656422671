import { Container } from "@mui/material";
import SwiperHomeSection from "./SwiperHome";
import SubBannerSection from "./SubBanner";
import TagsHome from "./TagsHome";
import MiddleBannerSection from "./MiddleBanner";
import BestSellers from "./BestSellers";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";

export default function HomePage({ preview_mode = false, preview_content }) {
  const [page_content, setPageContent] = useState({});
  const [ready_to_render, setReadyToRender] = useState(false);

  const getPageContent = async () => {
    const res = await get("get-page/home/all");
    setPageContent(res);
    setReadyToRender(true);
  };

  useEffect(() => {
    if (preview_mode) {
      setPageContent(preview_content);
      setReadyToRender(true);
    } else {
      getPageContent();
    }
  }, []);

  useEffect(() => {
    setPageContent(preview_content);
  }, [preview_content]);

  return (
    ready_to_render && (
      <div>
        <SwiperHomeSection banners={page_content.banners || []} />

        <Container maxWidth="lg">
          <SubBannerSection sub_banners={page_content.sub_banners || []} />
          <TagsHome
            tags={page_content.tags || []}
            tags_styles={page_content.tags_styles}
          />
        </Container>

        <MiddleBannerSection mid_banners={page_content.mid_banners || []} />

        <Container maxWidth="lg">
          <BestSellers />
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    )
  );
}
