import CustomText from "components/CustomText";
import ImagePicker from "components/ImagePicker";

export default function ProductComparisonSection({
  product_comparison,
  setProductComparison,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {/* <CustomText text="Tabela" size="1.2rem" bold /> */}
      <ImagePicker
        title="Tabela"
        image={product_comparison.table}
        setImage={(image_obj) =>
          setProductComparison({ ...product_comparison, table: image_obj })
        }
      />
    </div>
  );
}
