import { Box, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { STORAGE_URL } from "utilities/variables";
import CustomText from "components/CustomText";
import { checkBase64 } from "utilities/helpers";

export default function AdminAttachImageProduct({
  header_images,
  setHeaderImages,
  deleted_header_images,
  setDeletedHeaderImages,
}) {
  const handleAddImage = (e) => {
    // get base64
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setHeaderImages([
        ...header_images,
        {
          id: header_images.length,
          image_file: e.target.files[0],
          image: reader.result,
        },
      ]);
    };
  };

  const handleDeleteImage = (image_object) => {
    // if it has created_at, means the image has been stored in the database
    if (image_object.created_at) {
      setDeletedHeaderImages([...deleted_header_images, image_object.id]);
    }
    setHeaderImages(
      header_images.filter((item) => item.id !== image_object.id)
    );
    toast.success("Imagem removida com sucesso");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <CustomText text="Adicionar imagens" size="1rem" bold />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <input
          id="admin-product-add-image"
          type="file"
          onChange={handleAddImage}
          accept="image/*"
          hidden
        />
        <label
          htmlFor="admin-product-add-image"
          style={{
            textTransform: "uppercase",
            padding: "0.4rem 1rem",
            backgroundColor: "#333333",
            color: "#FFFFFF",
            cursor: "pointer",
            textAlign: "center",
            width: "fit-content",
          }}
        >
          Adicionar imagem
        </label>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          height: "max-content",
          gap: "1rem",
          overflow: "hidden",
          overflowY: "auto",
          height: "max-content",
          maxHeight: "30rem",
          padding: "0.4rem",
        }}
      >
        {header_images.map((item, index) => (
          <ImageItem
            key={index}
            {...{
              text: item.text,
              image: checkBase64(item.image),
              id: item.id,
              handleRemove: () => handleDeleteImage(item),
            }}
          />
        ))}
      </div>
    </div>
  );
}

const ImageItem = ({ text, image, handleRemove }) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "12rem",
        height: "14rem",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        position: "relative",
        transition: "all 0.3s",
        "&:hover img": {
          transform: "scale(1.1)",
        },
        border: "1px solid black",
      }}
    >
      <img
        src={image}
        alt="Ícone do cartão"
        style={{
          position: "absolute",
          top: "0",
          left: 0,
          width: "100%",
          height: "100%",
          marginBottom: "1rem",
          objectFit: "cover",
          transition: "all 0.3s",
          zIndex: 2,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "max-content",
            zIndex: 3,
            margin: "0.4rem",
            overflow: "hidden",
            display: "flex",
            border: "1px solid #c4c4c4",
          }}
        >
          <ClearIcon
            sx={{
              fontSize: "2rem",
              padding: "0.2rem",
              cursor: "pointer",
              transition: "all 0.3s",
              "&:hover": {
                color: "#ff0000",
              },
            }}
            onClick={handleRemove}
          />
        </div>
      </div>
    </Box>
  );
};
