import { useEffect, useState } from "react";
import { checkBase64 } from "utilities/helpers";

export default function ProductImagesSection({ header_images }) {
  const [selected_image, setSelectedImage] = useState(0);

  const handleChangeSelectedImage = (index) => {
    setSelectedImage(header_images[index]?.image);
  };

  useEffect(() => {
    setSelectedImage(header_images[0]?.image);
  }, [header_images]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
        marginTop: "1rem",
      }}
    >
      <div>
        <img
          src={checkBase64(selected_image)}
          alt="Product"
          style={{
            width: "100%",
            aspectRatio: "1/1",
            objectFit: "cover",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          gap: "0.5rem",
        }}
      >
        {header_images.map((image, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              width: "25%",
              cursor: "pointer",
            }}
            onClick={() => handleChangeSelectedImage(index)}
          >
            <img
              src={checkBase64(image.image)}
              alt="Product"
              style={{
                width: "100%",
                aspectRatio: "1/1",
                objectFit: "cover",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
