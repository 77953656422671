import RichEditor from "components/RichEditor.jsx";

export default function MainTextSection({
  main_text,
  setMainText,
  handleChangeMainText,
}) {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <input
          type="button"
          style={{
            textTransform: "uppercase",
            padding: "0.4rem 1rem",
            backgroundColor: "#333333",
            color: "#FFFFFF",
            cursor: "pointer",
            textAlign: "center",
            width: "fit-content",
          }}
          value={"Salvar texto"}
          onClick={handleChangeMainText}
        />
      </div>
      <br />

      <RichEditor
        placeholder="Digite aqui o termo de uso"
        text={main_text}
        setText={setMainText}
      />
    </>
  );
}
