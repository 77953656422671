import CustomText from "components/CustomText";
import { Reorder } from "framer-motion";
import { useEffect, useState } from "react";
import { get, post } from "utilities/requests";
import { toast } from "react-toastify";
import SubBannerItem from "./SubBannerItem";

export default function SubBannerSection({ sub_banners, setSubBanners }) {
  const getSubBanners = async () => {
    const res = await get("get-page/home/sub-banners");
    setSubBanners(res.sub_banners);
    console.log(res.sub_banners);
  };

  const handleAddSubBanner = async (e) => {
    if (sub_banners.length >= 3) {
      toast.error("Limite de 3 sub banners atingido");
      return;
    }

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const res = await post("edit-page/home/sub-banner", formData);

    if (res.httpCode === 200) {
      toast.success("Banner adicionado com sucesso");
      getSubBanners();
    } else {
      toast.error(res.message || "Erro ao adicionar banner");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <CustomText text="Sub banner" size="1.4rem" bold />
        <input
          id="upload_sub_banner_button"
          type="file"
          onChange={handleAddSubBanner}
          accept="image/*"
          hidden
        />
        <label
          htmlFor="upload_sub_banner_button"
          style={{
            textTransform: "uppercase",
            padding: "0.4rem 1rem",
            backgroundColor: "#333333",
            color: "#FFFFFF",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          Adicionar sub banner
        </label>
      </div>
      <CustomText
        text="<b>Atenção:</b> limite de 3 sub banners."
        size="1rem"
        color="#b50d0d"
      />

      <Reorder.Group axis={"y"} onReorder={setSubBanners} values={sub_banners}>
        <table
          style={{
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Imagem
              </th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {sub_banners.map((banner, index) => (
              <SubBannerItem
                key={index}
                {...{ banner, refetch: getSubBanners }}
              />
            ))}
            {sub_banners.length === 0 && (
              <td
                colSpan={2}
                style={{
                  textAlign: "center",
                  paddingTop: "1rem",
                }}
              >
                Não há sub banners cadastrados
              </td>
            )}
          </tbody>
        </table>
      </Reorder.Group>

      {/* change order confirmation */}
      {/* <AnimatePresence>
        {JSON.stringify(banners) != JSON.stringify(tempBanners) && (
          <SaveOrderButton
            onClick={() => {
              orderMutation.mutate();
              setBanners([tempBanners, tempBanners]);
            }}
            onCancel={() => setBanners([banners, banners])}
          />
        )}
      </AnimatePresence> */}

      <hr
        style={{
          display: "flex",
          maxWidth: "46rem",
          width: "100%",
          marginBottom: "4rem",
          backgroundColor: "#cccccc",
          border: "none",
          height: "1px",
        }}
      />
    </div>
  );
}
