import { Star } from "@phosphor-icons/react";
import CustomText from "components/CustomText";

export default function ReviewMessageSection() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        {[...Array(5)].map((_, index) => {
          return (
            <Star
              key={index}
              style={{
                marginRight: "0.2rem",
                fontSize: "1rem",
                color: "#333333",
              }}
              weight="fill"
            />
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          fontSize: "0.84rem",
          color: '#878787'
        }}
      >
        <span>Por Cleide Cristina de Araújo Pacheco</span>
        <span>05/08/2023</span>
      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}>
        <span style={{fontWeight: 'bold'}}>Excelente</span>
      <span style={{fontSize: '0.86rem'}}>Estou amando.</span>
      </div>
    </div>
  );
}
