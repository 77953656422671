/**
 * @param {String} text
 * @param {String} className
 * @param {String} variant
 * @param {String} justify
 * @param {String} size
 * @param {Boolean} bold
 * @param {String} color
 * @param {Number} lines
 * @param {Object} style
 *
 * @returns {JSX.Element}
 *
 **/

import { Box } from "@mui/material";

const CustomText = ({
  text,
  className,
  size,
  bold,
  color,
  variant,
  justify,
  lines = null,
  style,
}) => {
  return (
    <Box
      className={className}
      component={variant || "span"}
      sx={[
        lines && {
          display: "-webkit-box",
          WebkitLineClamp: lines,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        {
          color: color || "black",
          fontSize: size || "1rem",
          fontWeight: bold ? "bold" : "normal",
          textAlign: justify || "left",
        },
        style,
      ]}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default CustomText;
