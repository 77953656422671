import { STORAGE_URL } from "./variables";
import image_not_found from "assets/static/image_low_quality_low.jpg";

export const handleLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  // localStorage.removeItem("first_access");
  // dispatch({ type: "login", payload: { token: null, user: null } });
  // dispatch({ type: "cart_items", payload: null });
  window.location.href = "/";
};

// check if string has base64
export const checkBase64 = (string) => {
  if (!string) return image_not_found;

  if (string.includes("data:image")) {
    return string;
  } else {
    return STORAGE_URL + string;
  }
};

export const discountCalculator = (price, discount, is_percentage_discount) => {
  price = undoMoneyMask(price);
  discount = undoMoneyMask(discount);

  if (is_percentage_discount) {
    return price - (price * discount) / 100;
  } else {
    return price - discount;
  }
};

// money mask
export const moneyMask = (value) => {
  try {
    value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
  } catch (e) {
    try {
      value = value
        .toString()
        .replace(".", "")
        .replace(",", "")
        .replace(/\D/g, "");
    } catch (e) {
      return 0;
    }
  }

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(value) / 100
  );

  return "R$ " + result;
};

// undo money mask
export const undoMoneyMask = (value) => {
  try {
    value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
  } catch (e) {
    try {
      value = value
        .toString()
        .replace(".", "")
        .replace(",", "")
        .replace(/\D/g, "");
    } catch (e) {
      return 0;
    }
  }

  return value;
};

export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};
