import { Container } from "@mui/material";
import { checkBase64 } from "utilities/helpers";

export default function ProductsComparisonSection({ product_comparison }) {
  return (
    <div
      style={{
        background: product_comparison.background_color_2
          ? `linear-gradient(180deg, ${product_comparison.background_color_1} 0%, ${product_comparison.background_color_2} 100%)`
          : product_comparison.background_color_1,
        padding: "4rem 0",
      }}
    >
      <Container maxWidth="lg">
        <div
          style={{
            width: "100%",
          }}
        >
          <img
            src={checkBase64(product_comparison.table?.image)}
            alt="Product"
            style={{
              width: "100%",
              height: "auto",
              aspectRatio: "1/1",
              objectFit: "contain",
            }}
          />
        </div>
      </Container>
    </div>
  );
}
