import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  convertFromRaw,
  ContentState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DOMPurify from "dompurify";
import editor_types from "./editor_types";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export default function RichEditor({
  text,
  setText,
  placeholder = "Digite aqui o texto",
  toolbarHidden = false,
  editor_options = "full",
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  // sanitize html
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html, { FORCE_BODY: true }),
    };
  }

  // convert editor state to html and sanitize it
  useEffect(() => {
    // let html = convertToHTML(editorState.getCurrentContent());
    const rawContentState = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setText(createMarkup(rawContentState).__html);
  }, [editorState]);

  // initialize editor with text
  useEffect(() => {
    if (text) {
      const contentBlock = htmlToDraft(text);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, []);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      toolbarHidden={toolbarHidden}
      placeholder={placeholder}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      localization={{
        locale: "pt",
      }}
      toolbar={{
        options: editor_types[editor_options],
      }}
    />
  );
}
