import { Slider } from "@mui/material";
import CustomText from "components/CustomText";
import ImagePicker from "components/ImagePicker";
import RichEditor from "components/RichEditor.jsx";

export default function DescriptionSection({ description, setDescription }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <CustomText text="Texto de cima" size="1rem" bold />
      <RichEditor
        placeholder="Digite o texto aqui"
        text={description.top_text}
        setText={(text) => setDescription({ ...description, top_text: text })}
        editor_options="only_text"
      />
      <ImagePicker
        title="Imagem de cima"
        image={description.top_image}
        setImage={(image_obj) =>
          setDescription({ ...description, top_image: image_obj })
        }
      />

      <br />
      <br />

      <CustomText text="Texto de baixo" size="1rem" bold />
      <RichEditor
        placeholder="Digite o texto aqui"
        text={description.bottom_text}
        setText={(text) =>
          setDescription({ ...description, bottom_text: text })
        }
        editor_options="only_text"
      />
      <ImagePicker
        title="Imagem de baixo"
        image={description.bottom_image}
        setImage={(image_obj) =>
          setDescription({ ...description, bottom_image: image_obj })
        }
      />

      <br />
      <br />

      <ImagePicker
        title="Imagem de fundo"
        image={description.background_image}
        setImage={(image_obj) =>
          setDescription({ ...description, background_image: image_obj })
        }
      />

      <CustomText text="Opacidade" size="1rem" bold />
      <Slider
        value={description.background_opacity}
        onChange={(event, value) =>
          setDescription({ ...description, background_opacity: value })
        }
        min={0}
        max={1}
        step={0.1}
        valueLabelDisplay="auto"
      />
    </div>
  );
}
