import { STORAGE_URL } from "utilities/variables";

export default function SubBannerSection({ sub_banners }) {
  return (
    <div
      style={{
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        gap: "0.8rem",
      }}
    >
      {sub_banners.map((sub_banner, index) => (
        <img
          key={index}
          src={STORAGE_URL + sub_banner.image_path}
          alt="sub banner"
          style={{
            width: "25rem",
            height: "15rem",
            objectFit: "cover",
          }}
        />
      ))}
    </div>
  );
}
