import CustomText from "components/CustomText";
import { useEffect } from "react";
import { checkBase64 } from "utilities/helpers";

export default function ImagePicker({ title = "Imagem", image, setImage }) {
  const handleBannerChange = async (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setImage({
        image_file: e.target.files[0],
        image: reader.result,
      });
    };
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <CustomText text={title} size="1rem" bold />
      <CustomText text="Clique para alterar" size="1rem" />
      <label
        style={{
          width: "100%",
          aspectRatio: "6/3",
          overflow: "hidden",
          backgroundColor: "#F2F2F2",
          border: "2px solid #ccc",
        }}
      >
        <img
          src={checkBase64(image?.image)}
          alt="Banner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleBannerChange}
        />
      </label>
    </div>
  );
}
