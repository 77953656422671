import { Container } from "@mui/material";
import CustomText from "components/CustomText";
import { checkBase64 } from "utilities/helpers";

export default function NutritionalInfoSection({ nutritional_info }) {
  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: "4rem 1rem",
        background: nutritional_info.background_color_2
          ? `linear-gradient(180deg, ${nutritional_info.background_color_1} 0%, ${nutritional_info.background_color_2} 100%)`
          : nutritional_info.background_color_1,
      }}
    >
      <CustomText
        text={"Informações Nutricionais"}
        variant={"h3"}
        size={"1.94rem"}
        justify={"center"}
        bold
        style={{
          textTransform: "uppercase",
          marginBottom: "3rem",
        }}
        color={nutritional_info.primary_text_color}
      />

      <div
        dangerouslySetInnerHTML={{
          __html: nutritional_info.subtitle,
        }}
      />

      <br />
      <br />

      <hr
        style={{
          display: "flex",
          maxWidth: "46rem",
          margin: "0 auto",
          backgroundColor: "#cccccc",
          border: "none",
          height: "1px",
        }}
      />

      <br />
      <br />

      <div
        style={{
          width: "100%",
        }}
      >
        <img
          src={checkBase64(nutritional_info.table?.image)}
          alt="Product"
          style={{
            width: "100%",
            height: "auto",
            aspectRatio: "1/1",
            objectFit: "contain",
          }}
        />
      </div>

      <br />
      <br />

      <div
        dangerouslySetInnerHTML={{
          __html: nutritional_info.description,
        }}
      />
    </Container>
  );
}
