import { IconButton } from "@mui/material";
import { PencilLine, Trash } from "@phosphor-icons/react";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { get, post } from "utilities/requests";
import TagModal from "./TagModal";
import TagItem from "./TagItem";
import StylingSections from "pages/Admin/Produtos/EditarProduto/sections/HeaderInfo/StylingSections";

export default function TagsSection({
  tags,
  setTags,
  tags_styles,
  setTagsStyles,
}) {
  const [open_tag_modal, setOpenTagModal] = useState(false);
  const [modal_action, setModalAction] = useState("add");
  const [new_tag, setNewTag] = useState({
    text: "",
  });

  const getTags = async () => {
    const res = await get("get-page/home/tags");
    setTags(res.tags);
  };

  const handleSaveStyles = async () => {
    let form = new FormData();
    form.append("tags_styles", JSON.stringify(tags_styles));

    const res = await post("edit-page/home/tags_styles/update", form);
    if (res.httpCode === 200) {
      toast.success("Estilos salvos com sucesso!");
    } else {
      toast.error("Erro ao salvar estilos!");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <TagModal
        {...{
          visible: open_tag_modal,
          setOpen: setOpenTagModal,
          action: modal_action,
          refetch: getTags,
          new_tag,
          setNewTag,
        }}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <CustomText text="Tags" size="1.4rem" bold />

        <label
          style={{
            textTransform: "uppercase",
            padding: "0.4rem 1rem",
            backgroundColor: "#333333",
            color: "#FFFFFF",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={() => {
            setOpenTagModal(true);
            setModalAction("add");
            setNewTag({
              text: "",
            });
          }}
        >
          Adicionar tag
        </label>
      </div>

      <table>
        <thead>
          <tr>
            <th
              style={{
                textAlign: "left",
              }}
            >
              Nome
            </th>
            <th
              style={{
                textAlign: "right",
              }}
            >
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag, index) => (
            <TagItem
              key={index}
              {...{
                tag,
                refetch: getTags,
                setModalAction,
                setNewTag,
                setOpenTagModal,
              }}
            />
          ))}
          {tags.length === 0 && (
            <td
              colSpan={2}
              style={{
                textAlign: "center",
                paddingTop: "1rem",
              }}
            >
              Não há tags cadastradas
            </td>
          )}
        </tbody>
      </table>

      <StylingSections
        {...{
          section_state: tags_styles,
          setSectionState: setTagsStyles,
        }}
        has_background_color_1
        has_background_color_2
        has_primary_text_color
      />
      <label
        style={{
          textTransform: "uppercase",
          padding: "0.4rem 1rem",
          backgroundColor: "#333333",
          color: "#FFFFFF",
          cursor: "pointer",
          textAlign: "center",
        }}
        onClick={() => {
          handleSaveStyles();
        }}
      >
        Salvar estilos
      </label>

      <hr
        style={{
          display: "flex",
          maxWidth: "46rem",
          width: "100%",
          marginBottom: "4rem",
          backgroundColor: "#cccccc",
          border: "none",
          height: "1px",
        }}
      />
    </div>
  );
}
