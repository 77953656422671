import { FormControlLabel, Switch } from "@mui/material";
import CustomInput from "components/CustomInput";
import CustomText from "components/CustomText";
import { moneyMask } from "utilities/helpers";

export default function HeaderInfoSection({ header_info, setHeaderInfo }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <CustomText text="Informações principais" size="1rem" bold />
      <CustomInput
        placeholder="Nome do produto"
        value={header_info.name}
        onChange={(e) =>
          setHeaderInfo({ ...header_info, name: e.target.value })
        }
        required
      />
      <CustomInput
        placeholder="Subtítulo"
        value={header_info.subtitle}
        onChange={(e) =>
          setHeaderInfo({ ...header_info, subtitle: e.target.value })
        }
        required
      />
      <textarea
        rows={1}
        placeholder="Descrição"
        value={header_info.description}
        onChange={(e) =>
          setHeaderInfo({ ...header_info, description: e.target.value })
        }
        required
      />

      <CustomInput
        type={"text"}
        placeholder="Preço"
        value={header_info.price}
        onChange={(e) =>
          setHeaderInfo({ ...header_info, price: moneyMask(e.target.value) })
        }
        required
      />

      <CustomInput
        type={header_info.is_percentage_discount ? "number" : "text"}
        placeholder="Desconto"
        value={header_info.discount}
        onChange={(e) =>
          setHeaderInfo({
            ...header_info,
            discount: header_info.is_percentage_discount
              ? e.target.value
              : moneyMask(e.target.value),
          })
        }
      />

      <FormControlLabel
        control={
          <Switch
            checked={header_info.is_percentage_discount}
            onChange={(e) =>
              setHeaderInfo({
                ...header_info,
                is_percentage_discount: e.target.checked,
              })
            }
          />
        }
        label={
          <CustomText
            text={
              header_info.is_percentage_discount
                ? "Desconto em porcentagem"
                : "Desconto em reais"
            }
            size="0.9rem"
          />
        }
        sx={{
          marginBottom: "1rem",
        }}
      />

      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <CustomInput
          type={"number"}
          placeholder="Quantidade"
          value={header_info.quantity}
          onChange={(e) =>
            setHeaderInfo({ ...header_info, quantity: e.target.value })
          }
          required
        />
        <CustomInput
          type={"number"}
          placeholder="Quantidade máxima por compra"
          value={header_info.purchase_quantity_limit}
          onChange={(e) =>
            setHeaderInfo({
              ...header_info,
              purchase_quantity_limit: e.target.value,
            })
          }
          required
        />
      </div>
    </div>
  );
}
