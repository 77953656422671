import CustomText from "components/CustomText";
import { STORAGE_URL } from "utilities/variables";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { get, post } from "utilities/requests";
import windowReloadPage from "utilities/window_reload";

export default function BannerSection() {
  const [banner, setBanner] = useState("");

  const getBanner = async () => {
    const res = await get("get-page/pesquisar/banner");
    setBanner(res.banner);
  };

  const handleBannerChange = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const res = await post("edit-page/pesquisar/banner", formData);

    if (res.httpCode === 200) {
      toast.success("Banner alterado com sucesso");
      windowReloadPage();
    } else {
      toast.error(res.message || "Erro ao alterar banner");
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <CustomText text="Banner" size="1.4rem" bold />
        <CustomText text="Clique para alterar" size="1rem" />
        <label
          htmlFor="banner-pesquisar"
          style={{
            width: "100%",
            aspectRatio: "6/3",
            overflow: "hidden",
            backgroundColor: "#F2F2F2",
            border: "2px solid #ccc",
          }}
        >
          <img
            src={STORAGE_URL + banner?.image_path}
            alt="Banner"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <input
            type="file"
            accept="image/*"
            name="banner-pesquisar"
            id="banner-pesquisar"
            style={{ display: "none" }}
            onChange={handleBannerChange}
          />
        </label>
      </div>
    </div>
  );
}
