import { isEmptyObject } from "utilities/helpers";

const checkRichEditorEmptyString = (text) => {
  if (text === "" || text === "<p></p>") {
    return true;
  }
  return false;
};

export default function validate(data) {
  // secao 1
  if (data.header_images.length === 0) {
    return "Selecione pelo menos uma imagem para o produto";
  }

  // secao 2
  if (checkRichEditorEmptyString(data.description.top_text)) {
    return "Preencha o texto de cima da descrição";
  } else if (checkRichEditorEmptyString(data.description.bottom_text)) {
    return "Preencha o texto de baixo da descrição";
  } else if (isEmptyObject(data.description.top_image)) {
    return "Selecione uma imagem para o texto de cima da descrição";
  } else if (isEmptyObject(data.description.bottom_image)) {
    return "Selecione uma imagem para o texto de baixo da descrição";
  }

  // secao 3
  if (isEmptyObject(data.product_comparison.table)) {
    return "Selecione uma imagem para a tabela de comparação";
  }

  // secao 4
  if (isEmptyObject(data.nutritional_info.table)) {
    return "Selecione uma imagem para a tabela de informações nutricionais";
  }

  return false;
}
