import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/autoplay";
import { STORAGE_URL } from "utilities/variables";

const SwiperHomeSection = ({ banners }) => {
  const img_style = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <>
      <Swiper
        style={{
          width: "100%",
          height: "28rem",
        }}
        modules={[Autoplay]}
        autoplay={{
          delay: 5000,
        }}
        speed={1000}
        slidesPerView={1}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <img
              src={STORAGE_URL + banner.image_path}
              alt="Carousel banner"
              style={img_style}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default SwiperHomeSection;
