import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

export default function TagsHome({ tags, tags_styles }) {
  console.log(tags_styles);
  return (
    <>
      <h3
        style={{
          textTransform: "uppercase",
          fontSize: "1.25rem",
          fontWeight: "bold",
          textAlign: "center",
          margin: "2rem 0 1rem 0",
        }}
      >
        Suplementos Alimentares para Saúde e Bem-Estar
      </h3>
      <Swiper
        style={{
          width: "100%",
          padding: "0 2rem",
          marginBottom: "1rem",
        }}
        navigation={true}
        modules={[Navigation]}
        speed={500}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          380: {
            slidesPerView: 2,
          },
          520: {
            slidesPerView: 3,
          },
          670: {
            slidesPerView: 4,
          },
          1000: {
            slidesPerView: 5,
          },
        }}
      >
        {tags.map((tag, index) => (
          <SwiperSlide key={index}>
            <TagItem text={tag.text} styles={tags_styles} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

function TagItem({ text, styles }) {
  return (
    <div
      style={{
        height: "5rem",
        width: "11rem",
        backgroundColor: styles.background_color_1,
        borderRadius: "20rem",
        padding: "0.2rem",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "50%",
          height: "100%",
          borderRadius: "20rem 0 0 20rem",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: styles.background_color_2,
        }}
      >
        <div
          style={{
            width: 0,
            height: 0,
            borderTop: "0.8rem solid transparent",
            borderBottom: "0.8rem solid transparent",

            borderRight: `2rem solid ${styles.background_color_1}`,
          }}
        />
      </div>

      <span
        style={{
          fontWeight: "bold",
          fontSize: "1.1rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: styles.primary_text_color,
        }}
      >
        {text}
      </span>
    </div>
  );
}
