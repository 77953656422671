import { STORAGE_URL } from "utilities/variables";

export default function MiddleBannerSection({ mid_banners }) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          height: "36.8rem",
          flex: 1,
          minWidth: "30rem",
          backgroundImage: `url(${STORAGE_URL + mid_banners[0]?.image_path})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div
        style={{
          height: "36.8rem",
          flex: 1,
          minWidth: "30rem",
          backgroundImage: `url(${STORAGE_URL + mid_banners[1]?.image_path})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  );
}
