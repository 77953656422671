import { CaretRight, House } from "@phosphor-icons/react";
import CustomText from "components/CustomText";

export default function BreadcrumbSection({ header_info }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "0.4rem",
        alignItems: "center",
        color: header_info.secondary_text_color,
      }}
    >
      <House
        size={20}
        style={{
          marginRight: "0.8rem",
        }}
      />
      <CaretRight size={16} />
      <CustomText
        text={"Nome do produto"}
        color={header_info.primary_text_color}
      />
    </div>
  );
}
