import { Box } from "@mui/material";
import { Heart } from "@phosphor-icons/react";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { discountCalculator, moneyMask } from "utilities/helpers";
import { STORAGE_URL } from "utilities/variables";

export default function ProductCard({ product }) {
  const navigate = useNavigate();
  const [discounted_price, setDiscountedPrice] = useState(0);

  const handleProductClick = () => {
    navigate(`/produto/${product.name.toLowerCase().replaceAll(" ", "-")}`);
  };

  useEffect(() => {
    setDiscountedPrice(
      discountCalculator(
        product.header_info.price,
        product.header_info.discount,
        product.header_info.is_percentage_discount
      )
    );
  }, [product.header_info]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.4rem",
        cursor: "pointer",
        "&:hover > div > img": {
          transform: "scale(1.1)",
        },
        "&:hover .add-to-cart-warning": {
          bottom: "0!important",
        },
      }}
      onClick={handleProductClick}
    >
      <div
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "1.6rem",
            left: "1.6rem",
            zIndex: "1",
            fontSize: "1.2rem",
            backgroundColor: "white",
            borderRadius: "0.4rem",
            width: "1.8rem",
            height: "1.8rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heart weight="fill" />
        </div>
        <img
          src={
            product.header_info.header_images !== undefined &&
            STORAGE_URL + product.header_info.header_images[0]?.image
          }
          alt={product.name}
          style={{
            width: "100%",
            aspectRatio: "9/10",
            objectFit: "cover",
            transition: "transform 150ms ease-out",
          }}
        />

        <div
          className="add-to-cart-warning"
          style={{
            position: "absolute",
            bottom: "-2rem",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            color: "white",
            width: "100%",
            textAlign: "center",
            transition: "150ms ease-out",
            fontSize: "0.9rem",
            padding: "0.4rem 0",
          }}
        >
          <span>Adicionar ao carrinho</span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          gap: "0.4rem",
        }}
      >
        <CustomText text={product.name} bold size="0.9rem" />
        <CustomText
          text={product.header_info.subtitle}
          color="#878787"
          size="0.9rem"
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomText
          text={product.header_info.price}
          bold
          style={{
            textDecoration: "line-through",
          }}
          color="#878787"
          size="0.9rem"
        />
        <CustomText text={moneyMask(discounted_price)} bold size="0.9rem" />
      </div>
    </Box>
  );
}
