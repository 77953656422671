import { BrowserRouter } from "react-router-dom";
import RoutesContainer from "routes";
import "./responsive.css";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './colors.css'
import { QueryClient, QueryClientProvider } from "react-query";

let theme = createTheme();
theme = responsiveFontSizes(theme);
// with material ui theme, its possible to make text components responsive automatically

function App() {
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <RoutesContainer />
          </BrowserRouter>
        </QueryClientProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
