import { Box, Container } from "@mui/material";
import CustomText from "components/CustomText";
import ProductCard from "components/ProductCard";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";

export default function RelatedProductsSection() {
  const [products, setProducts] = useState([]);

  const getProduct = async () => {
    const res = await get("products/list");
    setProducts(res.products);
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "max-content",
        gap: "2rem",
        zIndex: 1,
        alignItems: "center",
        padding: "4rem 0",
      }}
    >
      <CustomText
        text={"Produtos relacionados"}
        variant={"h3"}
        size={"1.94rem"}
        justify={"center"}
        bold
        style={{
          textTransform: "uppercase",
          marginBottom: "1rem",
        }}
      />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          },
          gap: "1.8rem",
        }}
      >
        {/* {products.map((product, i) => (
          <ProductCard key={i} {...{ product }} />
        ))} */}
      </Box>
    </Container>
  );
}
