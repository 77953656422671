import { useEffect, useState } from "react";
import CustomText from "components/CustomText";
import { CaretRight, House } from "@phosphor-icons/react";
import EditFormSobreNos from "./EditForm";
import SobreNosPage from "pages/SobreNos";
import { toast } from "react-toastify";
import { get, post } from "utilities/requests";

export default function EditarPaginasSobreNos() {
  const [show_preview, setShowPreview] = useState(true);

  // section states
  const [main_text, setMainText] = useState("");
  const [banner, setBanner] = useState("");
  const [ready_to_render, setReadyToRender] = useState(false);

  const handleChangeMainText = async () => {
    const formData = new FormData();

    formData.append("main_text", main_text);
    const res = await post("edit-page/sobre-nos/main-text", formData);

    if (res.httpCode === 200) {
      toast.success("Texto alterado com sucesso");
    } else {
      toast.error(res.message || "Erro ao alterar texto");
    }
  };

  const getPageContent = async () => {
    const res = await get("get-page/sobre-nos/all");
    setMainText(res.main_text?.main_text || "");
    setBanner(res.banner);
    setReadyToRender(true);
  };

  useEffect(() => {
    getPageContent();
  }, []);

  // auto resize preview
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 968) {
        setShowPreview(false);
      } else {
        setShowPreview(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* form */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: show_preview ? "60%" : "100%",
          transition: "500ms ease",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.4rem",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.4rem",
                fontSize: "1.4rem",
                alignItems: "center",
              }}
            >
              <House weight="fill" />
              <CustomText
                text="Editar página sobre nós"
                size="1.4rem"
                bold
                style={{
                  margin: "1rem 0",
                }}
              />
            </div>
            <label
              style={{
                textTransform: "uppercase",
                padding: "0.4rem 1rem",
                backgroundColor: "#333333",
                color: "#FFFFFF",
                cursor: "pointer",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => {
                setShowPreview(!show_preview);
              }}
            >
              {show_preview ? (
                <>
                  <CaretRight weight="fill" />
                  <span>Esconder preview</span>
                </>
              ) : (
                <>
                  <CaretRight
                    weight="fill"
                    style={{
                      transform: "rotate(180deg)",
                      fontSize: "1.4rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  <span>Mostrar preview</span>
                </>
              )}
            </label>
          </div>
          <CustomText
            text="<b>Atenção: preview 100% fiel ao resultado final."
            size="1rem"
            color="#4d4d4d"
          />
        </div>
        {ready_to_render && (
          <EditFormSobreNos
            {...{
              banner,
              setBanner,
              main_text,
              setMainText,
              handleChangeMainText,
            }}
          />
        )}
      </div>

      {/* preview */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: show_preview ? "100%" : "0%",
          overflowY: "auto",
          borderLeft: "1px solid #ccc",
          transition: "500ms ease",
        }}
      >
        <SobreNosPage
          {...{
            preview_mode: true,
            preview_content: {
              banner,
              main_text,
            },
          }}
        />
      </div>
    </div>
  );
}
