import { Container } from "@mui/material";
import CategoriesAside from "./Categories";
import ProductsSection from "./Products";
import MobileCategoriesAside from "./MobileCategories";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";
import { STORAGE_URL } from "utilities/variables";

export default function PesquisarPage() {
  const [showModalCategories, setShowModalCategories] = useState(false);
  const categories = [
    "Ver todos",
    "Black Friday",
    "Lançamentos",
    "Mais Vendidos",
    "Pele",
    "Sono",
  ];

  const [page_content, setPageContent] = useState({});

  const getPageContent = async () => {
    const res = await get("get-page/pesquisar/all");
    setPageContent(res);
  };

  useEffect(() => {
    getPageContent();
  }, []);

  return (
    <div>
      <div>
        <img
          src={STORAGE_URL + page_content.banner?.image_path}
          alt="Banner categoria"
          style={{
            width: "100%",
            height: "28rem",
            objectFit: "cover",
          }}
        />
      </div>

      <Container maxWidth="lg">
        <div
          style={{
            display: "flex",
            padding: "1.6rem 0",
            gap: "1.6rem",
          }}
        >
          <CategoriesAside {...{ categories }} />
          <MobileCategoriesAside
            {...{ categories, showModalCategories, setShowModalCategories }}
          />

          <ProductsSection
            {...{ showModalCategories, setShowModalCategories }}
          />
        </div>
      </Container>
    </div>
  );
}
