import { Box, Container } from "@mui/material";
import { Star } from "@phosphor-icons/react";
import CustomText from "components/CustomText";
import StarsCountSection from "./StarsCount";
import ReviewMessageSection from "./ReviewMessage";

export default function ReviewSection() {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "max-content",
        gap: "2rem",
        zIndex: 1,
        alignItems: "center",
        padding: "4rem 1rem",
      }}
    >
      <CustomText
        text={"Avaliações"}
        variant={"h3"}
        size={"1.94rem"}
        justify={"center"}
        bold
        style={{
          textTransform: "uppercase",
          marginBottom: "1rem",
        }}
      />

      <div
        style={{
          display: "flex",
          width: "100%",
          maxWidth: "32rem",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <CustomText text={"5"} size={"2rem"} bold />
          <div
            style={{
              display: "flex",
            }}
          >
            {[...Array(5)].map((_, index) => {
              return (
                <Star
                  key={index}
                  style={{
                    marginRight: "0.2rem",
                    fontSize: "1.8rem",
                    color: "#333333",
                  }}
                  weight="fill"
                />
              );
            })}
          </div>
          <CustomText text={"Avaliações"} size={"0.9rem"} color={"#878787"} />
        </div>

        <button
          style={{
            height: "max-content",
            padding: "0.6rem 3rem",
            border: "none",
            backgroundColor: "#333333",
            color: "white",
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          Avalie este produto
        </button>
      </div>

      <hr
        style={{
          display: "flex",
          maxWidth: "46rem",
          width: "100%",
          margin: "3rem auto",
          backgroundColor: "#cccccc",
          border: "none",
          height: "1px",
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: "2rem",
          width: "100%",
          maxWidth: "46rem",
        }}
      >
        <StarsCountSection />
        <ReviewMessageSection />
      </Box>
    </Container>
  );
}
