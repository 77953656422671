import { useEffect, useState } from "react";
import CustomText from "components/CustomText";
import { CaretRight, House } from "@phosphor-icons/react";
import PesquisarPage from "pages/Pesquisar";
import EditFormPesquisar from "./EditForm";

export default function EditarPaginasPesquisar() {
  const [show_preview, setShowPreview] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 968) {
        setShowPreview(false);
      } else {
        setShowPreview(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* form */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: show_preview ? "60%" : "100%",
          transition: "500ms ease",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.4rem",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.4rem",
                fontSize: "1.4rem",
                alignItems: "center",
              }}
            >
              <House weight="fill" />
              <CustomText
                text="Editar página de pesquisa"
                size="1.4rem"
                bold
                style={{
                  margin: "1rem 0",
                }}
              />
            </div>
            <label
              style={{
                textTransform: "uppercase",
                padding: "0.4rem 1rem",
                backgroundColor: "#333333",
                color: "#FFFFFF",
                cursor: "pointer",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => {
                setShowPreview(!show_preview);
              }}
            >
              {show_preview ? (
                <>
                  <CaretRight weight="fill" />
                  <span>Esconder preview</span>
                </>
              ) : (
                <>
                  <CaretRight
                    weight="fill"
                    style={{
                      transform: "rotate(180deg)",
                      fontSize: "1.4rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  <span>Mostrar preview</span>
                </>
              )}
            </label>
          </div>
          <CustomText
            text="<b>Atenção:o preview pode não ser 100% fiel ao resultado final.</b>  Ex: A responsividade do menu lateral de categorias usa como base a tela completa para ser responsivo, e não o tamanho do preview."
            size="1rem"
            color="#4d4d4d"
          />
        </div>
        <EditFormPesquisar />
      </div>

      {/* preview */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: show_preview ? "100%" : "0%",
          overflowY: "auto",
          borderLeft: "1px solid #ccc",
          transition: "500ms ease",
        }}
      >
        <PesquisarPage />
      </div>
    </div>
  );
}
