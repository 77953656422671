import CustomText from "components/CustomText";
import ImagePicker from "components/ImagePicker";
import RichEditor from "components/RichEditor.jsx";

export default function AdminNutritionalInfoSection({
  nutritional_info,
  setNutritionalInfo,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <RichEditor
        placeholder="Subtítulo"
        text={nutritional_info.subtitle}
        setText={(text) =>
          setNutritionalInfo({ ...nutritional_info, subtitle: text })
        }
        editor_options="only_text"
      />
      <ImagePicker
        title="Tabela nutricional"
        image={nutritional_info.table}
        setImage={(image_obj) =>
          setNutritionalInfo({ ...nutritional_info, table: image_obj })
        }
      />
      <RichEditor
        placeholder="Descrição"
        text={nutritional_info.description}
        setText={(text) =>
          setNutritionalInfo({ ...nutritional_info, description: text })
        }
        // editor_options="only_text"
      />
    </div>
  );
}
